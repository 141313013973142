export const reportColumns = () => {
  return [
    {
      title: '序号',
      width: 45,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '集污点编号',
      align: 'center',
      width: 100,
      dataIndex: 'spot.no'
    },
    {
      title: '集污点名称',
      align: 'left',
      width: 180,
      dataIndex: 'spot.name',
      scopedSlots: { customRender: 'name' }
    },
   /* {
      title: '人员名称',
      align: 'left',
      width: 80,
      dataIndex: 'fromName'
    },*/
    {
      title: '来电电话',
      align: 'center',
      width: 110,
      ellipsis: true,
      dataIndex: 'fromPhone'
    },
    {
      title: '区域名称',
      align: 'left',
      width: 135,
      ellipsis: true,
      dataIndex: 'spot.areaName',
      // scopedSlots: { customRender: 'areaName' }
    },
    /*{
      title: '区域编号',
      align: 'center',
      minWidth: 125,
      dataIndex: 'spot.areaNo'
    },*/
    /*{
      title: '上报原因',
      align: 'left',
      ellipsis: true,
      dataIndex: 'reason'
    },*/
    {
      title: '上报地址',
      align: 'left',
      width: 230,
      ellipsis: true,
      dataIndex: 'fromAddress',
      scopedSlots: { customRender: 'fromAddress' }
    },
    {
      title: '上报类型',
      align: 'center',
      width: 75,
      dataIndex: 'type',
      scopedSlots: { customRender: 'type' }
    },
    {
      title: '处理状态',
      align: 'center',
      width: 75,
      dataIndex: 'handleStatus',
      scopedSlots: { customRender: 'handleStatus' }
    },
    {
      title: '车辆负责人',
      align: 'center',
      width: 100,
      dataIndex: 'dispatcher.name'
    },
    {
      title: '任务状态',
      align: 'center',
      width: 75,
      dataIndex: 'bindStatus',
      scopedSlots: { customRender: 'bindStatus' }
    },
    {
      title: '清运司机',
      align: 'center',
      width: 75,
      dataIndex: 'driver.name'
    },
    {
      title: '上报时间',
      align: 'center',
      width: 160,
      dataIndex: 'fromTime'
    },
    {
      title: '最晚清运时间',
      align: 'center',
      width: 100,
      dataIndex: 'latestDate'
    },
    {
      title: '操作',
      align: 'center',
      width: 180,
      fixed: 'right',
      scopedSlots: { customRender: 'action' }
    }
  ]
}
export const myReportColumns = () => {
  return [
    {
      title: '序号',
      width: 45,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    /*  {
        title: '集污点编号',
        align: 'center',
        width: 120,
        dataIndex: 'spot.no'
      },*/
    {
      title: '集污点名称',
      align: 'left',
      width: 80,
      dataIndex: 'spot.name',
      scopedSlots: { customRender: 'name' }
    },
    {
      title: '人员名称',
      align: 'center',
      width: 80,
      dataIndex: 'fromName'
    },
    /*{
      title: '来电电话',
      align: 'center',
      width: 110,
      ellipsis: true,
      dataIndex: 'fromPhone'
    },*/
    {
      title: '区域名称',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'spot.areaName'
    },
    {
      title: '上报地址',
      align: 'left',
      width: 230,
      ellipsis: true,
      dataIndex: 'fromAddress',
      scopedSlots: { customRender: 'fromAddress' }
    },
    {
      title: '上报类型',
      align: 'center',
      width: 75,
      dataIndex: 'type',
      scopedSlots: { customRender: 'type' }
    },
    /* {
       title: '车辆负责人',
       align: 'center',
       width: 90,
       dataIndex: 'dispatcher.name'
     },*/
    {
      title: '任务状态',
      align: 'center',
      width: 75,
      dataIndex: 'bindStatus',
      scopedSlots: { customRender: 'bindStatus' }
    },
    {
      title: '清运司机',
      align: 'center',
      width: 90,
      dataIndex: 'driver.name'
    },
    {
      title: '清运车辆',
      align: 'center',
      width: 90,
      dataIndex: 'carLoopRecord.carInfo.platNumber'
    },
    {
      title: '上报时间',
      align: 'center',
      width: 160,
      dataIndex: 'fromTime'
    }
    /* {
       title: '最晚清运时间',
       align: 'center',
       width: 100,
       dataIndex: 'latestDate'
     },*/
  ]
}
export const damageReportColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '人员名称',
      align: 'center',
      width: 140,
      dataIndex: 'fromName'
    },
    {
      title: '上报原因',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'reason'
    },
    {
      title: '上报地址',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'fromAddress'
    },
    {
      title: '来电电话',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'fromPhone'
    },
    {
      title: '故障类型',
      align: 'center',
      width: 120,
      dataIndex: 'damageType'
    },
    {
      title: '值班人员',
      align: 'center',
      width: 120,
      dataIndex: 'officer'
    },
    {
      title: '绑定工单',
      align: 'center',
      width: 120,
      dataIndex: 'bind',
      scopedSlots: { customRender: 'bind' }
    },
    {
      title: '派车信息',
      align: 'center',
      width: 120,
      dataIndex: 'dispatchStatus ',
      scopedSlots: { customRender: 'dispatch' }
    },
    {
      title: '处理状态',
      align: 'center',
      width: 120,
      dataIndex: 'handleStatus',
      scopedSlots: { customRender: 'handleStatus' }
    },
    {
      title: '上报类型',
      align: 'center',
      width: 140,
      dataIndex: 'type',
      scopedSlots: { customRender: 'type' }
    },
    {
      title: '上报时间',
      align: 'center',
      width: 200,
      dataIndex: 'fromTime'
    },
    /* {
       title: '确认人',
       align: 'center'，
       width: '90px',
       dataIndex: 'confirmUserId'
     },*/
    {
      title: '操作',
      align: 'center',
      width: 140,
      fixed: 'right',
      scopedSlots: { customRender: 'action' }
    }
  ]
}
export const damageReportDispatchColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '人员名称',
      align: 'center',
      width: 140,
      dataIndex: 'fromName'
    },
    {
      title: '上报原因',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'reason'
    },
    {
      title: '上报地址',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'fromAddress'
    },
    {
      title: '来电电话',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'fromPhone'
    },
    {
      title: '故障类型',
      align: 'center',
      width: 120,
      dataIndex: 'damageType'
    },
    {
      title: '值班人员',
      align: 'center',
      width: 120,
      dataIndex: 'officer'
    },
    {
      title: '绑定工单',
      align: 'center',
      width: 120,
      dataIndex: 'bind',
      scopedSlots: { customRender: 'bind' }
    },
    {
      title: '处理状态',
      align: 'center',
      width: 120,
      dataIndex: 'handleStatus',
      scopedSlots: { customRender: 'handleStatus' }
    },
    {
      title: '上报类型',
      align: 'center',
      width: 140,
      dataIndex: 'type',
      scopedSlots: { customRender: 'type' }
    },
    {
      title: '上报时间',
      align: 'center',
      width: 200,
      dataIndex: 'fromTime'
    },
    /* {
       title: '确认人',
       align: 'center'，
       width: '90px',
       dataIndex: 'confirmUserId'
     },*/
    {
      title: '操作',
      align: 'center',
      width: 140,
      fixed: 'right',
      scopedSlots: { customRender: 'action' }
    }
  ]
}
export const reportFeedbackColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '集污点编号',
      align: 'center',
      width: 120,
      dataIndex: 'spot.no'
    },
    {
      title: '集污点名称',
      align: 'left',
      width: 240,
      dataIndex: 'spot.name'
    },
    /*{
      title: '人员名称',
      align: 'left',
      width: 140,
      dataIndex: 'fromName'
    },*/
    {
      title: '来电电话',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'fromPhone'
    },
    {
      title: '上报原因',
      align: 'left',
      ellipsis: true,
      dataIndex: 'reason'
    },
    {
      title: '上报地址',
      align: 'left',
      ellipsis: true,
      dataIndex: 'fromAddress'
    },
    {
      title: '处理状态',
      align: 'center',
      width: 120,
      dataIndex: 'handleStatus',
      scopedSlots: { customRender: 'handleStatus' }
    },
    {
      title: '任务状态',
      align: 'center',
      width: 100,
      dataIndex: 'bindStatus',
      scopedSlots: { customRender: 'bindStatus' }
    },
    {
      title: '上报类型',
      align: 'center',
      dataIndex: 'type',
      width: 100,
      scopedSlots: { customRender: 'type' }
    },
    {
      title: '上报时间',
      align: 'center',
      width: 200,
      dataIndex: 'fromTime'
    },
    {
      title: '操作',
      align: 'center',
      width: 140,
      fixed: 'right',
      scopedSlots: { customRender: 'action' }
    }
  ]
}
export const damageReportConfirmColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '人员名称',
      align: 'center',
      width: 140,
      dataIndex: 'fromName'
    },
    {
      title: '上报原因',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'reason'
    },
    {
      title: '上报地址',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'fromAddress'
    },
    {
      title: '来电电话',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'fromPhone'
    },
    {
      title: '故障类型',
      align: 'center',
      width: 120,
      dataIndex: 'damageType'
    },
    {
      title: '值班人员',
      align: 'center',
      width: 120,
      dataIndex: 'officer'
    },
    {
      title: '绑定工单',
      align: 'center',
      width: 120,
      dataIndex: 'bind',
      scopedSlots: { customRender: 'bind' }
    },
    {
      title: '派车信息',
      align: 'center',
      width: 120,
      dataIndex: 'dispatchStatus ',
      scopedSlots: { customRender: 'dispatch' }
    },
    {
      title: '处理状态',
      align: 'center',
      width: 120,
      dataIndex: 'handleStatus',
      scopedSlots: { customRender: 'handleStatus' }
    },
    {
      title: '上报类型',
      align: 'center',
      width: 140,
      dataIndex: 'type',
      scopedSlots: { customRender: 'type' }
    },
    {
      title: '上报时间',
      align: 'center',
      width: 200,
      dataIndex: 'fromTime'
    },
    /* {
       title: '确认人',
       align: 'center'，
       width: '90px',
       dataIndex: 'confirmUserId'
     },*/
    {
      title: '操作',
      align: 'center',
      width: 160,
      fixed: 'right',
      scopedSlots: { customRender: 'action' }
    }
  ]
}
export const damageReportFormVO = () => {
  return {
    reportRecord: {
      id: null,
      spot: { id: null },
      orgId: '',
      driverId: null,
      dispatcherId: null,
      fromName: '',
      fromPhone: '',
      fromTime: '',
      fromAddress: '',
      reason: null,
      type: 'TEL',
      longitude: '',
      latitude: '',
      gatherSpotType: 'DANWENG',
      handleStatus: '',
      bindStatus: '',
      resolution: '', //调度中心处理意见
      officer: '', //调度中心值班人
      officePhone: '', //值班电话
      handleResult: '', //现场处理意见
      handlePerson: '', //现场处理人
      confirmResult: '', //确认结果
      feedbackPerson: '', //回访人
      feedbackResult: '',
      confirmPerson: '', //确认人
      ifUrgent: false,
      latestDate: '',
      latestDateTime: '',
      state: 0
    },
    imageIds: [],
    images: [],
    carLoopRecord: {
      id: null,
      department: {
        id: null,
        name: ''
      },
      carInfo: {
        id: null,
        fullName: ''
      },
      driver: {
        id: null,
        name: ''
      },
      volume: '',
      destination: '',
      recordIds: '', //对应的车辆任务IDS
      recordNames: '', //对应的车辆任务名称
      startTime: '', //开始时间
      endTime: '', //结束时间
      summary: '', //清运概述
      mileage: '', //执行公里
      duration: '', //清运时长
      durationStr: '', //清运时长
      status: '', //执行状态
    }, //清运记录
    loopImages: [], //清运图片
    recordTrail: {} //清运轨迹
  }
}
export const updateIfUrgentFormVO = () => {
  return {
    id: null,
    ifUrgent: false
  }
}
export const dispatchFormVO = () => {
  return {
    id: null,
    ifUrgent: false,
    driverId: null,
    latestDate: ''
  }
}

export const reportTypeList = () => {
  return [
    { value: 'TEL', label: '电话上报' },
    { value: 'WECHAT', label: '微信上报' },
    { value: 'APP', label: '巡检上报' },
    { value: 'OTHER', label: '其他途径' }
  ]
}
export const processStatusList = () => {
  return [
    { value: 'SCHEDULED', label: '已调度' },
    { value: 'PROCESSING', label: '处理中' },
    { value: 'PROCESSED', label: '待回访' },
    /*{ value: 'FEEDBACK_COMPLETE', label: '待确认' },*/
    { value: 'FEEDBACK_COMPLETE', label: '已完成' }
  ]
}
export const bindStatusList = () => {
  return [
    { value: 'NOTOPEN', label: '未审核' },
    { value: 'OPTIONAL', label: '待接单' },
    { value: 'CHECKED', label: '已接单' },
    { value: 'DOING', label: '清运中' },
    { value: 'COMPLETE', label: '已清运' }
  ]
}

// 统计报表表头
export const statisticsColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '时间',
      align: 'center',
      children: [
        {
          title: '日',
          align: 'center',
          key: 'day',
          width: 100,
          customRender: (text, record) => {
            let fromTime = record.fromTime
            if (fromTime) {
              if (fromTime.slice(8, 9) === '0') {
                return fromTime.slice(9, 10)
              } else {
                return fromTime.slice(8, 10)
              }
            }
          }
        },
        {
          title: '时分',
          align: 'center',
          key: 'hour_minute',
          width: 100,
          customRender: (text, record) => {
            return `${record.fromTime.slice(11, 16)}`
          }
        }
      ]
    },
    {
      title: '来电人',
      align: 'center',
      children: [
        {
          title: '姓名',
          align: 'center',
          dataIndex: 'fromName',
          width: 120
        },
        {
          title: '电话',
          align: 'center',
          dataIndex: 'fromPhone',
          width: 120
        }
      ]
    },
    {
      title: '值班员',
      align: 'center',
      children: [
        {
          title: '姓名',
          align: 'center',
          dataIndex: 'officer',
          width: 120
        },
        {
          title: '电话',
          align: 'center',
          dataIndex: 'officePhone',
          width: 120
        }
      ]
    },
    {
      title: '内容',
      align: 'center',
      width: 300,
      ellipsis: true,
      scopedSlots: { customRender: 'reason' }
    },
    {
      title: '办理结果',
      align: 'center',
      width: 150,
      ellipsis: true,
      dataIndex: 'confirmResult'
    },
    {
      title: '备注',
      align: 'center',
      width: 150
    }
  ]
}

// 部门值班电话
export const departmentPhoneNumbers = () => {
  return [
    { value: '30', label: '967001-1' }, // 兴庆
    { value: '29', label: '967001-2' }, // 金凤
    { value: '31', label: '967001-3' }, // 贺兰
    { value: '46', label: '967001-4' } // 中宁
  ]
}