<template>
  <a-popover v-model="visible" placement="bottom" trigger="click">
    <div slot="content" style="width: 500px;height: 300px;">
      <a-radio-group v-model="radioGroupValue" size="small" @change="radioGroupChange">
        <a-radio-button value="a">区</a-radio-button>
        <a-radio-button value="b">镇</a-radio-button>
        <a-radio-button value="c">村</a-radio-button>
      </a-radio-group>
      <table class="areaTable" cellpadding="0" cellspacing="0">
        <div v-if="radioGroupValue==='a'">
          <tr>
            <td class="areaTable-top">银川</td>
            <td style="padding-top: 3px;">
              <template v-for="i in areaOption">
                <a :class="selectArea.data.findIndex(e => e.value === i.value) !== -1?'select-area-a areaTable-a':'areaTable-a'" @click="onClickArea('a',i)">
                  {{i.label}}</a>
              </template>
            </td>
          </tr>
        </div>
        <template v-for="i in areaOption">
          <div v-if="radioGroupValue==='b'">
            <tr>
              <td class="areaTable-top">{{i.label}}</td>
              <td style="padding-top: 3px;">
                <template v-for="j in i.children">
                  <a :class="selectArea.data.findIndex(b => b.value === j.value) !== -1?'select-area-a areaTable-a':'areaTable-a'" @click="onClickArea('b',j)">{{j.label}}</a>
                </template>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <div style="height: 12px; visibility: hidden;"></div>
              </td>
            </tr>
          </div>
          <div v-if="radioGroupValue==='c'">
            <template v-for="j in i.children">
              <tr>
                <td class="areaTable-top">{{j.label}}</td>
                <td style="padding-top: 3px;">
                  <template v-for="e in j.children">
                    <a :class="selectArea.data.findIndex(c => c.value === e.value) !== -1?'select-area-a areaTable-a':'areaTable-a'" @click="onClickArea('c',e)">{{e.label}}</a>
                  </template>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <div style="height: 12px; visibility: hidden;"></div>
                </td>
              </tr>
            </template>
          </div>
        </template>
      </table>
      <br>
      <a-button type="primary" @click="search" size="small" style="float:right">查询</a-button>
    </div>
    <a-button type="primary">
      选择区域
    </a-button>
  </a-popover>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'AreaSelection',
    data () {
      return {
        areaOption: {},
        radioGroupValue: 'a',
        selectArea: {
          type: null,
          data: []
        },
        visible: false
      }
    },
    mounted () {
      this.loadAreaOption()
    },
    methods: {
      radioGroupChange (e) {
        this.radioGroupValue = e.target.value
      },
      onClickArea (type, item) {
        if (this.selectArea.type === null || this.selectArea.type === type) {
          if (this.selectArea.data.findIndex(e => e.value === item.value) === -1) {
            this.selectArea.type = type
            this.selectArea.data.push(item)
          } else {
            this.selectArea.data.splice(this.selectArea.data.findIndex(e => e.value === item.value), 1)
            if (this.selectArea.data.length === 0) {
              this.selectArea.type = null
            }
          }
        } else {
          this.$message.warning('请选择区域级别相同的行政区')
        }
        var areaCodes = this.getAreaCode()
        this.$emit('onclickArea', areaCodes)
      },
      getAreaCode () {
        const areaCodes = []
        this.selectArea.data.forEach((e) => {
          if (this.selectArea.type === 'a') {
            areaCodes.push((e.value).substr(0, 2))
          } else if (this.selectArea.type === 'b') {
            areaCodes.push((e.value).substr(0, 4))
          } else {
            areaCodes.push((e.value).substr(0, 6))
          }
        })
        return areaCodes
      },
      loadAreaOption () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.areaDictionaryApi.cascade,
          noTips: true,
          success: (data) => {
            this.areaOption = data.body
          }
        })
      },
      search () {
        this.visible = false
        this.$emit('search', '')
      }
    }
  }
</script>
<style scoped>
  .areaTable {
    border-collapse: collapse;
    border-spacing: 0;
    overflow-y: auto;
    height: 220px;
    display: block;
    margin-top: 15px
  }

  .areaTable-top {
    padding-right: 8px;
    font-weight: 700;
    width: 70px;
    vertical-align: top;
    padding-top: 6px;
  }

  .areaTable-a {
    white-space: nowrap;
    margin: 0 10px;
    line-height: 30px;
    color: #3d6dcc;
    text-decoration: none;
    padding: 0 10px;
  }

  .select-area-a {
    color: red;
    border: 1px solid;
    border-radius: 30px;
  }
</style>