export const gatherspotSeachColumns = () => {
  return [
    {
      title: '编号',
      align: 'center',
      width: 120,
      dataIndex: 'no'
    },
    {
      title: '类型',
      align: 'center',
      width: 120,
      dataIndex: 'type',
      scopedSlots: { customRender: 'type' }
    },
    {
      title: '集污点名称',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'name'
      // scopedSlots: { customRender: 'name' }
    },
    {
      title: '容积（m³）',
      align: 'center',
      width: 100,
      dataIndex: 'bulk'
    },
    {
      title: '区域名称',
      align: 'center',
      width: 180,
      dataIndex: 'areaName'
    },
    {
      title: '门牌号',
      align: 'left',
      width: 100,
      dataIndex: 'houseNo'
    },
    {
      title: '区域编号',
      align: 'center',
      width: 100,
      dataIndex: 'areaNo'
    },
    {
      title: '负责人',
      align: 'center',
      width: 145,
      dataIndex: 'userName'
    },
    {
      title: '联系电话',
      align: 'center',
      width: 145,
      dataIndex: 'userPhone'
    },
    {
      title: '审核状态',
      align: 'center',
      width: 80,
      dataIndex: 'checkStatus',
      scopedSlots: { customRender: 'checkStatus' }
    },
    {
      title: '指派人',
      align: 'center',
      width: 90,
      dataIndex: 'dispatcherName'
    },
    {
      title: '司机',
      align: 'center',
      width: 90,
      dataIndex: 'driverName'
    },
    {
      title: '经度',
      align: 'center',
      width: 180,
      dataIndex: 'longitude'
    },
    {
      title: '纬度',
      align: 'center',
      width: 180,
      dataIndex: 'latitude'
    },
    {
      title: '清运次数',
      align: 'center',
      width: 180,
      dataIndex: 'cleanTotal'
    },
    {
      title: '详细位置',
      align: 'left',
      width: 245,
      ellipsis: true,
      dataIndex: 'location'
    }
  ]
}